@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,100..900;1,100..900&display=swap');
.mainservice__box {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 550px;
    width: 100%;
    position: relative;
    /* border: 1px solid purple; */

}

.mainservice__img {
    width: 100%;
    height: 100%;
    object-fit: inherit;
    position: absolute;
}
.mainservice__text {
    color: #FFFFFF;
    z-index: 2;
    font-weight: 600;

}
.mainservice__heading {
    margin: auto;
    font-size: 1.75rem;
    width: 65%;
    font-weight: 700;
    font-family: "Noto Serif";
}


@media screen and (max-width:700px) {
    .mainservice__heading {
        font-size:16px;
        text-align: center;
    
    }
    /* .mainservice__img {
       
        object-fit: contain;
       
    } */
    .mainservice__box {
      
        height: 250px;
       
    
    }
}


@media screen and (max-width:485px) {
    
    .mainservice__heading {
        font-size:12px;
        text-align: center;
    
    }

    /* .mainservice__img {
       
        object-fit: contain;
       
    } */
    .mainservice__box {
      
        height: 180px;
       
    
    }



    /* .mainservice__box {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 42vh;
        width: 98%;
        position: relative;
    }
    */
}