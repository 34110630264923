.main_container {
    height: 170px;
    width: 185px;
    display: flex;
    align-items: center;
    margin: 100px auto;
    flex-direction: column;
    box-shadow: inset 5px 4px 8px 1px #ccc;
    border-radius: 8px;
    border: 1px solid #ccc;
    
}
.heading {
    font-size: 12px;
    margin-top: 20px;
    padding-bottom: 5px
}

 .graph{
   height: 105px;
   width: 138px;
   position: relative;
}
.values{
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-size: 10px;
    font-weight: 500;


}
.months{
    display: flex;
    font-size: 10px;
    gap: 6px;
    margin-left: 28px;
    font-weight: 500;
}
.month{
    color: black;
    font-size: 10px;

}
.bar_box {
    display: flex;
    justify-content:end;
    align-items: end;
    gap: 10px;
    position: absolute;
    width: 90%;
    height: 82%;
    top:0;
    left: 10%;
}
    
.bar1 {
   width: 14px;
   height: 20px;
   border-radius: 2px;
   background-color:#FFDDC4;
}
.bar2 {
    width: 14px;
    height: 38px;
    border-radius: 2px;
    background-color:#FFCFAC;
}
.bar3 {
    width: 14px;
    height: 53px;
    border-radius: 2px;
    background-color:#FFB883;
}
.bar4 {
    width: 14px;
    height: 67px;
    border-radius: 2px;
    background-color:#FDA565;
}
.bar5 {
    width: 14px;
    height: 85px;
    border-radius: 2px;
    background-color:#F58634;
 }