.component_service{
    display: flex;
    flex-direction: column;
   height: 100%;
   gap: 50px;
   /* border: 1px solid red; */
   /* justify-content: space-evenly; */
margin-top: 90px;
margin-bottom: 50px;
}
/* @media screen and (max-width:1246px) {
    .component_service{
        height: 100%;
    } */
    
/* } */