.team__main_box {
    width: 90%;
    height: 520px;
    display: flex;
    justify-content:space-between;
    align-items: center;
    padding: 0px 25px;
    margin-bottom: 20px;
    margin-top: 20px;
    overflow:hidden;
}
.team_respo_body {
    display: none;
}



.team__left_heading {
    font-size: 240%;
}

.team__child_box {
    display: flex;
    width: 97%;
    justify-content: space-around;
    align-items: center;
    margin-left: 90px;
}
.team__left {
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: -100px;
    margin-left: -90px;
}
.team__lines {
    border-radius: 10px;
    width: 140px;
    height: 0px;
    border: 1.5px solid #F58634;
    margin-top: 10px;
}

.team__right {
    width: 600px;
    height: 100%;
    overflow:visible;
    position:relative;
    margin-top: 100px;
}

.team__column {
    width: 600px;
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    margin-top: -10px;
    animation: slide 15s linear infinite;
    animation-delay: 2s;
    animation-timing-function: ease-out;

    position: absolute;
    top: -20px;
    will-change: transform;
}
.team__box {
    display: flex;
    flex-direction: column;    
    box-shadow: 0px 2px 2px -1px gray;
    border-radius: 8px;

}

 .team__bigimg {
    width: 150px;
    height: 130px;
    border-radius: 5px;
    object-fit: fill;
}

.team__about {
    width: 150px;
    background-color: #FFF3F3;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: -5px;
    gap: 10px;
    padding: 10px;
}
.team__name {
    font-size: 10px;
}
.team__role {
    font-size: 8px;
    margin-top: -8px;
}

  @keyframes slide {

    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-67%);
    }
    
}
 
 
.team__right .team__box:nth-child(3) {
    height: 175px;
} 

.team__right .team__box:nth-child(7)::before {
    content: "Our Designers";
    text-align: center;
    color: #F58634;
    font-size: 18px;
    font-weight: 600;
    padding: 10px;
    margin-top: -44px;
    
}
.team__right .team__box:nth-child(2)::before {
    content: "Our Tech Experts     ";
    text-align: center;
    color: #F58634;
    font-size: 18px;
    font-weight: 600;
    margin-top: -25px;
    margin-bottom: 10px;
    
}   
.team__right .team__box:nth-child(2) {
    margin-top: -90px;
    height: 175px;
}  
.team__right .team__box:nth-child(5) {
    margin-top: -90px;
    height: 175px;
} 
.team__right .team__box:nth-child(8) {
    margin-top: -90px;
    height: 175px;
}  
.team__right .team__box:nth-child(11) {
    margin-top: -90px;
    height: 175px;
} 
.team__right .team__box:nth-child(14) {
    margin-top: -90px;
    height: 175px;
} 
.team__right .team__box:nth-child(16) {
    margin-left: 200px;
    margin-top: -90px;
    height: 175px;
} 
.team_respo_box {
    display: none;
}


.team__column::after {
    content: '';
    display: block;
    height: 65vh;
    transform: translateY(100%);
}
@media screen and (max-width: 1086px) {
    .team__left {
        margin-left: -10%;
    }
}
@media screen and (max-width: 1000px) {
    .team__main_box {
        width: 95%;
    }
    .team__left {
        margin-left: -5%;
        font-size: 12px;
    }
    .team__lines {
        width: 70%;
    }
}

@media screen and (max-width: 906px) {
    .team__main_box {
        width: 93%;
        padding: 0px 110px;
    }
    .team__left {
        margin-left: -30%;
        margin-right: 5%;
        font-size: 10px;
        width: 150px;
    }
}
@media screen and (max-width: 810px) {
    .team__main_box {
        width: 100%;
        padding: 0px 110px;
    }
    .team__left {
        margin-left: -30%;
        margin-right: 5%;
        font-size: 9px;
        width: 35%;
    }
    .team__lines {
        width: 50%;
    }
    .team__right {
        margin-right: 22%;
    }
}

@media screen and (max-width: 750px) {
    .team__main_box {
        width: 115%;
    }  
    .team__left {
        margin-left: -25%;
        margin-right: 5%;
        font-size: 8px;
        width: 25%;
    }
}
@media screen and (max-width: 665px) {
    .team__main_box {
        display: none;
    }
    .team_respo_body {
        display: flex;
        width: 100%;
        flex-direction: column;
    }
    .team_respo_container {
        height: 350px;
        margin: auto;
        position: relative;
        width: 85%;
        display: flex;
        overflow: hidden;
   
    }
    .team_respo_slide_container {
        display: flex;
        flex-direction: column;
        gap: 0px;
        animation: scroll 40s linear infinite;
    }
    @keyframes scroll {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(calc(-250px * 14));
        }
    }
    .team_respo_slide_image {
        height: 250px;
        width: 250px;
        display: flex;
        align-items: center;
        padding: 15px;
    }
    .team_respo_image {
        width: 100%;
        border-radius: 5px;
    }
    .team_respo_about {
        background-color: #FFF3F3;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 5px;
        margin-top: -23px;
        width: 220px;
        margin-left: 15px;
        padding: 5px 0px;
    }
    .team_respo_mainheading {
        color: #F58634;
        font-size: 180%;
        display: flex;
        justify-content: center;
        padding-top: 20px;
    }
}

@media screen and (max-width: 580px) {
    @keyframes scroll {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(calc(-240px * 15));
        }
    }
}
@media screen and (max-width: 450px) {
    @keyframes scroll {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(calc(-235px * 16));
        }
    }
}
