@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,100..900;1,100..900&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .btnlink_learn{
    text-decoration: none;
    color: white;
    background-color: transparent;
  }
  /* .btn .btnlink_learn:hover{
    text-decoration: none;
    color: black;
  
  } */
  .paralaxBox {
    background-image: url("../../../../../../public/Images/Learnhow/Learnhow.png");
    height: 400px;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .container {
    height: 400px;
    width: 100%;
    position: relative;
  
  }
  
  .shadow {
    height: 400px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    position: absolute;
    top: 0;
  
  } 
  .overlay {
    position: absolute;
    /* top: 100px; */
    top: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .heading {
    color: #FFFFFF;
    width: 70%;
    text-align: center;
    font-weight: 700;
    font-family: "Noto Serif";
    /* border: 1px solid yellow; */
  }
  .btn {
    padding: 15px 40px;
    font-weight: 700;
    background-color: transparent;
    border: 2px solid white;
    
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
    margin-top: 40px;
  }
  .btn:hover{
    background-color: white;
  }
  .btn:hover .btnlink_learn {
   
    color: black;
  }

  @media screen and (max-width:640px) {
    .overlay {
     
      top: 10%;
     
    }
    .heading {
      color: #FFFFFF;
      width: 90%;
      text-align: center;
      font-family: "Noto Serif";
      /* border: 1px solid yellow; */
    }
    .btn {
      padding: 10px 30px;
      
    }
  }

  @media screen and (max-width:640px) {
    .overlay {
     
      top: 10%;
     
    }
    .heading {
     
      width: 90%;

     
    }
    
  }