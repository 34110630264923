.container_faq {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    gap: 70px;
    align-items: center;
    height: 500px ;
    width: 100%;
    margin:auto;
    padding: 20px;
    /* border: olive 1px solid; */
  }
  
  .h1_faq {
text-align: center;
    font-size: 2rem;
    /* margin-bottom: 20px; */
  }
  
  .item_faq {
    display: flex;
    flex-direction: column;
    /* margin-top: 10px; */
    /* gap: 30px; */
    width: 95%;
    height: 40%;
    /* background-color: aqua; */
  }
  @media screen and (max-width:670px) {
    .container_faq {
      display: flex;
      flex-direction: column;
      /* justify-content: space-around; */
      padding: 6px;  
      /* gap: 50px;  */
      height: 100%;
    }
  
    .item_faq {
      width: 100%;
    }
  
  
  }

