.container_navbar{
    width: 100%;
    height: 93px;
    display: flex;
    justify-content: space-between;
    align-items: center;
   /* margin: auto; */
   padding-right: 20px;
 
  position: fixed;
  z-index: 11;
  background-color: white;
    /*  padding: auto; */
    /* border-bottom: 1px solid black;
    box-shadow:0 1px rgba(56, 52, 52, 0.131) */
}
/* .forhamburger_navbar{
    display: flex;
    flex-direction: column;
} */

.Hamburgerbtn_navbar{
    display: none;
}
.container_navbar:hover{
  /* border-bottom: 2px solid black; */
 
    box-shadow:0 2px rgba(56, 52, 52, 0.231)
}
.logo_navbar{
    width: 160px;
    height: 38px;
    /* margin-left: 20px; */
    margin-left: 65px;
    object-fit:initial;
  

}
.menu_navbar{
    display: flex;
    align-items: center;
    gap: 30px;
    list-style: none;

}
.btn_navbar{

  /* background-color: #FF7D29;
   border-radius: 16px;
   font-weight: bold;
   color: white;
  border:  #FF7D29;
  width: 150px;
  height: 40px;
  box-shadow:0 4px rgba(56, 52, 52, 0.331) inset ; */

/* padding-right: 20px; */
margin-right: 45px;
  background-color: #FF7D29;
  border-radius: 10px;
  font-weight: bold;
  color: white;
 border:  #1F3255;
 width: 150px;
 height: 40px;
 box-shadow:0 4px 4px 0 #0000003d ;
 /* font-size: 10px;
  font-weight: 700; */
  

}

.bgcolor1_navbar{
    background-color: #FF7D29;
}
.bgcolor2_navbar{
    background-color: #FF7D29;
}
.nocolor_navbar{
    background-color:white;
}
.menu_navbar li{
    font-size: 20px;
    font-weight:500;
}
.linktext{
    padding: 6px;
    border-radius: 6px; 
    text-decoration: none;
   color:  #000000b3;
   /* box-shadow:0 4px 4px 0 #0000003d ; */

}

.btnlinktext{
    text-decoration: none;
    color:  white;
}
.hamburger_navbar{
    display: none;
}

.hamburgermenu_navbar{
    display: none;
}
.mobilemenu{
    display: none;
}
.hamburgermenumobile_navbar{
    text-decoration: none;
   

}

@media screen and (max-width:1083px ) {
    .menu_navbar{
        display: none;
    }
    .menudiv_navbar{
        display: none;
    }
    .hamburger_navbar{
        display: flex;
        padding-left: 20px;
        flex-direction: column;
        /* border: 1px solid red; */
        
    }
    .container_navbar{
        position: fixed;
        display: flex;
        /* justify-content: space-between;*/
        align-items: center; 
        width: 100vw;
    }
    .hamburgermenu_navbar{
        display: flex;
        flex-direction: column;
        position: absolute;
        background-color: white;
        position: fixed;
        top: 93px;
        z-index: 31;
        width: 100%;
        height: 100%;
        border-right: 1px solid gray;
        /* align-items: center; */
        padding-left: 25px;
       
        animation: scale .1s linear 1;
        transform-origin: left;
    
    }

    @keyframes scale {
        0% {
          transform: scaleX(0%);
        }
       
        100% {
          transform: scaleX(100%);
        }
    }

    .hamburgermenumobile_navbar{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 80%;
        gap: 20px;
       list-style: none;
       font-size: 20px;
       font-weight: 700;

    }
    .container_navbar:hover{
        /* border-bottom: 2px solid black; */
          box-shadow:none
      }
      
      .container_navbar{
        width: 100%;
      }
      .logo_navbar {
        width: 130px;
        height: 41px;
        margin-left: 20px;
        object-fit: contain;
    }
      .Hamburgerbtn_navbar{
        display: block;

        background-color:  #FF7D29;
        border-radius: 10px;
        font-weight: bold;
        color: white;
       border:  #1F3255;
       width: 90px;
       height: 30px;
       box-shadow:0 4px 4px 0 #0000003d ;
       font-size: 10px;
        font-weight: 700;
     




        /* background-color: #FF7D29;
        border-radius: 16px;
        font-weight: bold;
        color: white;
       border:  #FF7D29;
       width: 90px;
       height: 25px;
       box-shadow:0 4px rgba(56, 52, 52, 0.331) inset ;
       font-size: 10px;
        font-weight: 400; */
       
      }
}



