.review_single_box {
    background-color: #ffeea937;
    width: 280px;
    height: 350px;
    display: flex;
    margin-top: 40px;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 4px 11px 8.3px 0 #0000002e;
}

.clint_name h4 {
    padding-top: 20px;
    font-size: 20px;
    font-weight: 500;
}
.clint_text {
    padding-top: 20px;
    font-size: 11px;
    width: 230px;
    text-align: center;
    
}

.review_ratingstar {
    display: flex;
    gap: 10%;
    margin-top: 5px;
    margin-right: 10%;
}

.masks_group {
    display: flex;
    margin-top: auto;
    margin-bottom: 20px;
    
}
.clint_img img {
   width: 50px;
   height: 50px;
   border-radius: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
}
.clint_logo {
    margin-left: -5px;
   background-color: #FFDBB2;
   width: 48px;
   height: 48px;
   border-radius: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: 30px;
   z-index: -1;
}


@media screen and (max-width: 450px) {
    .review_single_box {
        height: 350px;
    }  
}