@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Noto Sans", sans-serif;
  /* box-sizing: border-box; */
} 
.App_byte{
  display: flex;
  flex-direction: column;
  gap: 0px;
  

}
