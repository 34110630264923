.container_joinus{
    width: 97vw;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    margin-top: 5%;
    /* margin: auto; */
}
.heading_joinus{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.h1_joinus{
    font-size: 2.1rem;
   
}
.h1underline_joinus{
    margin: auto;
width: 70%;
height: 3px;
background-color:#F58634;
border-radius: 90px;

}
.roles_joinus{
    display: flex;
    flex-direction: column;
    height: 500px;
    width: 850px;
    gap: 25px;
    
}
.rolesheading_joinus{
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 1.2rem;
   
}
.headingdiv_joinus{
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 100%;
}
.rolesunderline_joinus{
   
width: 100%;
height: 1px;
background-color:#111110;
border-radius: 90px;
   
}
.currentopeningborder_joinus{
    width: 100%;
    height: 1px;
    
}
.currentopening_joinus{
    width: 100%px;
    height: 1px;
    background-color:#C5C5C5;
    border-radius: 90px;
}

.opening_joinus{

    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 1rem;
}
@media screen and (max-width:866px) {

    .roles_joinus{
       
        width: 550px;
    }
}
@media screen and (max-width:568px) {

    .roles_joinus{
       
        width: 320px;
    }
    .rolesheading_joinus{
        font-size: 1rem;
    }

    .opening_joinus{
        font-size: .8rem;
    }
}