.ourservices__main_box {
  height: 500px;
  padding: 0px 50px;
  margin-bottom: 30px;
}
.ourservices__heading {
  text-align: left;
  font-size: 23px;
  margin-bottom: revert;
}
.ourservices__descripation {
  text-align: left;
  width: 46%;
  font-size: 14px;
  font-weight: 400;
}

.ourservices__box {
  height: 380px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  
}
.ourservices__left_box {
  height: 100%;
  display: flex;
  align-items: end;
  justify-content: center;
  margin-left: -100px;
}
.ourservices__circle1 {
  height: 330px;
  width: 330px;
  border-radius: 100%;
  background-color: #008DFF;
  position: relative;
}
.ourservices__circle2 {
  height: 330px;
  width: 330px;
  border-radius: 100%;
  background-color: white;
  position: relative;
}

.ourservices__image {
  object-fit:contain;
  position: absolute;
  height: 120%;
  width: 100%;
  margin-left: 5px;
  padding-bottom: 70px;
}
.ourservices__right_box {
  width: 40%;
  height: 106%;
  display: flex;
  flex-direction: column;
  gap: 40px;

}

.ourservices__innerserviceitem {
display: flex;
cursor: pointer;
align-items: center;
width: 100%;  
justify-content: space-between;
}
.active {
opacity: 0.4;
width: 15px; 
height: 15px; 
border: 2px solid red; 
}

.active.plus_icon::before {
width: 15px; 
height: 0px; 
}

.active.plus_icon::before,
.active.plus_icon::after {
  content: '';
  position: absolute;
  background-color: orange; 
}


.ourservices__details_content {
padding-top: 4px;
font-size: 14px;
width: 95%;
animation-name: scale;
animation-duration: 2s;
animation-direction:alternate;
}

@keyframes scale {
0% {
  transform: translateY(-10%);

}
100% {
  transform: scaleY(100%);
}
}

.ourservices__details_content.active {
max-height: 100px;
opacity: 1;
}

.ourservices__name {
  font-weight: 600;
  text-wrap: nowrap;
}
.plus_icon {
  width: 15px; 
  height: 15px; 
  border: 2px solid orange; 
  border-radius: 50%; 
  position: relative; 
  display: flex; 
  justify-content: center; 
  align-items: center; 
}

.plus_icon::before,
.plus_icon::after {
  content: '';
  position: absolute;
  background-color: orange; 
}

.plus_icon::before {
  width: 2px; 
  height: 8px; 
}

.plus_icon::after {
  width: 8px; 
  height: 2px; 
}
@media screen and (max-width: 850px) {
  .ourservices__circle1 {
       height: 270px;
       width: 270px;
       margin-left: 30px;
  }
  .ourservices__circle2 {
       height: 270px;
       width: 270px;
       margin-left: 30px;
  }
  .ourservices__image {
     object-fit: scale-down;
     height: 83%;
     width: 79%;
     margin-left: 36px;
  margin-top: 30px;
  }
  .ourservices__right_box {
    gap: 35px;
    width: 40%;
  }
  .ourservices__innerserviceitem {
    width: 120%;
  }
  .ourservices__details_content {
     font-size: 12px;
     font-weight: 400;
     width: 104%;
  }
  .ourservices__main_box {
    height: 100%;
   
}
}

@media screen and (max-width: 700px) {
  .ourservices__circle1 {
    display: none;
  }
  .ourservices__circle2 {
    display: none;
  }
  .ourservices__heading {
    text-align: center;
  }
  .ourservices__descripation {
    text-align: center;
    width: 100%;
    font-size: 13px;
    margin-bottom: 40px;
  }
  .ourservices__box {
    display: flex;
    justify-content: center;
    width: 95%;
  }
  .ourservices__innerserviceitem {
    width: 150%;



  }
  .ourservices__details_content{
    width: 140%;
  }
}
@media screen and (max-width: 450px) {
  .ourservices__serviceItem {
    margin-left: -30px;
  }
  .ourservices__innerserviceitem {
    /* width: 210%; */
    width: 200%;
  }
  .ourservices__details_content{
    width: 202%;
  }
  .ourservices__main_box {
    margin-bottom: -20px;
  }
}
@media screen and (max-width: 435px) {
  .ourservices__right_box {
     gap: 32px;
  }
}

@media screen and (max-width: 370px) {
  .ourservices__main_box {
   height: 100%;
    margin-bottom: 0px;
    padding: 10px 30px;
 }
 .ourservices__main_box {
  margin-bottom: -10px;
}
.ourservices__serviceItem {
  margin-left: 0px;
}
 
  .ourservices__descripation {
    text-align: justify;
  }
  .ourservices__innerserviceitem {
    width: 209%;
  }
  .ourservices__right_box {
    gap: 30px;
    width: 42%;
  }
  .ourservices__name {
    margin-left: -33px;
  }
  .ourservices__details_content {
    margin-left: -32px;
    width: 180%;
  }
  
}

@media screen and (max-width: 348px) {
  .ourservices__main_box {
    margin-bottom: 50px;
  }
}
