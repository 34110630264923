.selected_date{
    color: #000000;
}
.unselected_date{
    color: #a99f9f;
}
.project_errmsg {
    color: red;
    font-size: 14px;
}
.placeholder_label {
    position: absolute;
    bottom: 12px;

    /* top: -15px; 
    left: 5px;  */
    border-radius: 4px;
    outline: none;
    border: 1px solid #F6F6F6;
    background-color: #F6F6F6;
    width: 125px;
   text-align: center;
   padding: 4px;
    height: 27px;
    font-size: 14px;
   
    /* pointer-events: none; */
    /* background-color: #000000; */
     /* Disable click interaction */
  }

.filenames_project {
    margin-top: 10px;
  }
  
  .filename_project {
    right: 125px;
    font-size: 14px;
    color: green;
    /* margin-top: 5px; */
    /* position: absolute;
    bottom: 60px; */
  }
  .fileupload_project {
    background-color: #F6F6F6;
    width: 640px;
    height: 230px;
    border: 1px dashed #000000;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    transition: background-color 0.3s ease;
  }
  
  .fileupload_project.dragging {
    background-color: #e0e0e0;
  }

.container_project{
    width: 97vw;
    height: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin: auto;
    /* border: 1px solid red ; */
    /* position: absolute;
    top: 100px; */
    
}
.headingdiv_project{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.heading_project{
    color: #1F3255;
    font-size:35px;
    font-weight: 700;
}
.headingP_project{
    font-size:18px;
    font-weight: 500;
}
.form_project{
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;
}
.formbox1_project{
    display: flex;
    gap: 40px;
}
.formelem_project{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.formcol1_project{
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.formlabel_project{
font-size: 16px;

}
.forminput_project{
border-radius: 4px;
outline: none;
border: 1px solid #F6F6F6;
background-color: #F6F6F6;
width: 300px;
height: 50px;
padding: 20px;
}

.textelem_project{
    display: flex;
    flex-direction: column;
    gap: 10px;
 

}
.textinput_project{
    border-radius: 4px;
    outline: none;
    border: 1px solid #F6F6F6;
    background-color: #F6F6F6;
    width: 640px;
    height: 100px;
    padding: 30px;
    resize: none; 

}
.fileupload_project{
    background-color: #F6F6F6;
    width: 640px;
    height: 230px;
    border: 1px dashed #000000;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.browse_project{
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 90px;
}
.browsetext_project{
position: absolute;
text-align: center;
color:#6A6A6A;
padding-top: 7px ;
width: 160px;
height: 40px;
/* padding-left: 5px; */
left: 0;
top:-17px;
border:.1px solid #888888;
border-radius: 4px;
box-shadow: .1px .1px .1px 0 #888888;

background-color: #F6F6F6;
}
.browseinput_project{
visibility: hidden;
}
.drag_project{
        width: 300px;
        height: 120px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column  ;
    }
    .img_project{
        object-fit: contain;
        width: 30px;
        height: 30px;
    }


.btn_project{
cursor: pointer;
    background-color: #FF7D29;
    border-radius: 10px;
    font-weight: bold;
    color: white;
   border:  #1F3255;
   width: 150px;
   height: 40px;
   box-shadow:0 4px 4px 0 #0000003d ;

}
@media screen and (max-width:660px) {
    .browse_project{
       top: 8px;
    }
    .container_project{
        height: 1400px;
    }
    .heading_project{
        font-size: 25px;
    }
    .headingP_project{
        font-size: 16px;
    }
    .formlabel_project{
        font-size: 14px;
    }
   .textelem_project{
    align-items: flex-start;
   }
    .formbox1_project{
        flex-direction: column;
        align-items: center;
    }
    .textinput_project{
        width: 330px;
        padding: 15px;
    }
    .textelem_project{
        /* align-items: center; */
    }
    .forminput_project{
        align-items: center;
        width: 330px;
        padding: 15px;
        
    }
    .fileupload_project{
        
        width: 330px;
        height: 100px;
    }
    .formelem_project{
        align-items: flex-start;
    }

    .filename_project {
        bottom: 70px;
        right: 120px;
    }
    .drag_project {
        display: none;
    }

}