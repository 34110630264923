
.linktext_aboutus{
    text-decoration: none;
   color: black;
    
}
.content2p_aboutus{
    text-align: justify;
    text-align-last: center;
    margin-top: 40px;
    font-size: 16px;
    /* color: red; */
    /* font-weight: bold; */
}
.container_aboutus{
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;
    height: 600px;
    /* border: #12005A 1px solid; */
    /* margin-top: 130px; */
    /* margin: auto; */
}

.content1_aboutus{
    width: 46%;
    /* padding: auto; */
    height: 100%;
    display: flex;
    align-items: center;
    justify-content:center ;

   /* background-color: aqua; */
}
.content2_aboutus{

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    /* margin-left: 20px; */
    width: 48%;
    height: 60%;
    /* padding: auto; */
    /* background-color: bisque; */
}
.imagebox_aboutus{
    position: relative;
}
.image_aboutus{
    width: 450px;
    height: 350px;
    object-fit: scale-down;
}
.logo_aboutus{
    width: 110px;
    height: 110px;
}
.logo1_aboutus{
 position: absolute;
 bottom: -80px;
 right: 110px;
}
.logo2_aboutus{
    position: absolute;
    bottom: 80px;
    right: -60px;
}
.content2h2_aboutus {
    font-size: 1.25rem;
    font-weight: bold;
}
.content2h1_aboutus {
    /* font-weight: bold; */
    margin: 0 auto;
   /* width: 100%; */
    text-align: center;
    width: 70%;
    font-size: 2.2rem;
    font-weight:600;
    
    margin-top: 20px;
}

.btn_aboutus{
    padding: 10px 40px;
    background-color: white;
    /* box-shadow: 0px 1px #12005A inset; */
    /* box-shadow: 0px 1px  #12005A; */
    border: .6px solid #12005A;
    border-radius: 3px;
    font-size: 16px;
    margin-top: 80px;
    cursor: pointer;
}
@media screen and (max-width:1185px) {
    .container_aboutus{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }
    .content2h1_aboutus {
    width: 70%;
    text-align: center;
        font-size: 1.6rem;
       
    }
    .content2_aboutus{
        /* margin-left: 0px; */
        margin-top: 80px;
        width: 64%;
        height: 50%;
    }
    
}
@media screen and (max-width:720px){
    .container_aboutus{
       
        height: 100%;
    }
    .content2h2_aboutus {
        font-size: 1.25rem;
        font-weight: bold;
    }
    .content2h1_aboutus {
        text-align: center;

        font-size: 1.6rem;
       
    }

    .imagebox_aboutus{
        position: relative;
    }
    .image_aboutus{
        width: 270px;
        height: 240px;
        object-fit: scale-down;
    }
    .logo_aboutus{
        width: 70px;
        height: 75px;
        object-fit: contain;
    }
    .logo1_aboutus{
     position: absolute;
     bottom: -39px;
     right: 75px;
    }
    .logo2_aboutus{
        position: absolute;
        bottom: 45px;
        right: -33px;
    }


    .content2_aboutus{
        display: flex;
        flex-direction: column;
      
     gap: 5px;
        width: 90%;
        height: 83%;
        
    }

    .btn_aboutus{
        padding: 8px 20px;
    background-color: white;
    /* box-shadow: 0px 1px #12005A inset; */
    /* box-shadow: 0px 1px  #12005A; */
    border: .6px solid #12005A;
    border-radius: 3px;
    font-size: 12px;
    margin-top: 40px;

    }

    .content2p_aboutus{
        /* word-break: break-all; */
        
        text-align: justify;
        text-align-last: center;
        margin-top: 20px;
        font-size: 16px;
        /* font-weight: bold; */
    }
}
@media screen and (max-width:461px){
    .container_aboutus{
       
        height: 100%;
    }
    .content2h2_aboutus {
        font-size: 1.25rem;
        font-weight: bold;
    }
    .content2h1_aboutus {
    /* border: 1px solid red; */
        font-size: 1.4rem;
        width: 90%;
        text-align: center;

       
    }

    .imagebox_aboutus{
        position: relative;
    }
    .image_aboutus{
        width: 270px;
        height: 240px;
        object-fit: scale-down;
    }
    .logo_aboutus{
        width: 70px;
        height: 75px;
        object-fit: contain;
    }
    .logo1_aboutus{
     position: absolute;
     bottom: -39px;
     right: 75px;
    }
    .logo2_aboutus{
        position: absolute;
        bottom: 45px;
        right: -33px;
    }


    .content2_aboutus{
        display: flex;
        flex-direction: column;
      
     gap: 5px;
        width: 90%;
        height: 83%;
        
    }

    .btn_aboutus{
        padding: 8px 20px;
    background-color: white;
    /* box-shadow: 0px 1px #12005A inset; */
    /* box-shadow: 0px 1px  #12005A; */
    border: .6px solid #12005A;
    border-radius: 3px;
    font-size: 12px;
    margin-top: 40px;

    }

    .content2p_aboutus{
        text-align: center;
        margin-top: 20px;
        font-size: 16px;
    }
}