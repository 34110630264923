



.complete_{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  /* border: #e7810b 2px solid; */
  position: relative;
  animation-play-state: paused;
}
.box1_{
  width: 250px;
  border-radius: 10px;
  height: 130px;
  /* border: 2px solid red; */
  display: flex;
  position: absolute;
right: 240px;
  /* flex-direction: column; */
  align-items: center;
  position: relative;
  background-color:#FFDBB2 ;
  opacity: .7;
}
.box3_{
  width: 250px;
  border-radius: 10px;
  height: 130px;
  /* border: 2px solid red; */
  display: flex;
  position: absolute;
left: 240px;
  /* flex-direction: column; */
  align-items: center;
  position: relative;
  background-color:#FFDBB2 ;
  opacity: .7;
}

.slide_box1{
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.img_box1{
  height: 50px;
  width: 60px;
  object-fit: initial;
}
.h2_box1{
  font-size: 14px;
}
.box2_{
  width: 550px;
  height: 300px;
  /* border: 2px solid red; */
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  position: absolute;
  /* justify-content: space-between; */
  /* background-color:#ffffff; */
  background-color:#FFFAF4;
z-index: 2;
opacity: 1;
border-radius: 10px;
box-shadow: 0px 4px 4px 0px #0000002d;
/* transform: scale(1.02);
transition: all; */
animation-name: zoom;
animation-duration: 2s;
/* -o-animation-iteration-count: infinite; */
}
@keyframes zoom {
  from {transform: scale(1);}
  to {transform: scale(1.2);}
}


/* .slides_box2{
  border: 2px solid rgb(0, 255, 106);
  width: 100%;
  height: 100%; 
  display: flex;
  gap: 20px;
} */
.text_box2{
  /* border: 2px solid rgb(0, 255, 106); */
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 220px;
  align-items: center;
  gap: 30px;
}
.h2_box2{
  font-size: 16px;
}

.p_box2{
  text-align: center;
  font-size: 14px;
  
}
.centerslide_box2{
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 100%;
  width: 100%;
  /* border: 1px solid blue; */
  padding: 20px;
  gap: 20px;
  /* background-color:#ffffff41; */
}
.imgbox_box2{
  /* border: 2px solid rgb(0, 255, 106); */
      width: 30%;
 display: flex;
 align-items: center;
  justify-content: center;
}
.img_box2{
  height: 130px;
  width: 180px;
  object-fit: initial;
}
/* .box2_responsive{
  display: none;
} */

.slide_responsive{
  display: none;
  
}

@media screen and (max-width:1016px) {
.complete_{
height: 200px;
} 
.box1_{
  width: 160px;
  border-radius: 10px;
  height: 100px;
  /* border: 2px solid red; */
  
right: 190px;
  /* flex-direction: column; */
 
}
.box3_{
  width: 160px;
  border-radius: 10px;
  height: 100px;
 
left: 190px;
 
}


.img_box1{
  height: 45px;
  width: 55px;
  object-fit: initial;
}
.h2_box1{
  font-size: 12px;
}
.box2_{
width: 400px;
height: 200px;
 
 
}

.text_box2{
/* border: 2px solid rgb(0, 255, 106); */
display: flex;
flex-direction: column;
width: 70%;
height: 140px;
align-items: center;
gap: 10px;
}
.h2_box2{
font-size: 14px;
}

.p_box2{

font-size: 11px;

}
.centerslide_box2{
display: flex;
align-items: center;
flex-direction: row;
height: 100%;
width: 100%;
/* border: 1px solid blue; */
padding: 20px;
gap: 20px;
/* background-color:#ffffff41; */
}
.imgbox_box2{
/* border: 2px solid rgb(0, 255, 106); */
    width: 30%;
display: flex;
align-items: center;
justify-content: center;
}
.img_box2{
height: 70px;
width: 100px;
object-fit: initial;
}


}
@media screen and (max-width:700px) {

.box1_{
  display: none;
  
}
.box3_{
display: none;
  
 
}
.box2_{
display: none;
  
 
}

.complete_{display: none;}
.slide_responsive{
display: flex;
justify-content: center;
align-items: center ;
}

.box2_responsive_{
display: flex;
width: 100%;
align-items: center;
justify-content: center;
height: 300px;
border: #e7810b 1px solid;
  
}
.centerslide_responsive{
display: flex;
align-items: center;
flex-direction: row;
height: 100%;
width: 80%;
/* border: 1px solid blue; */
padding: 20px;
gap: 20px;
background-color:#FFFAF4;
box-shadow: 0px 4px 4px 0px #0000002d;
}
.pagination_responsive{
height: 40px;
}
.text_responsive{
display: flex;
flex-direction: column;
width: 70%;
height: 140px;
align-items: center;
gap: 10px;
}

.h2_responsive{
font-size: 14px;
}
.p_responsive{
font-size: 11px;
}

.imgbox_responsive{
width: 30%;
display: flex;
align-items: center;
 justify-content: center;
}

.img_responsive{
height: 70px;
width: 100px;
object-fit: initial;
}


}
@media screen and (max-width:415px) {






.text_responsive{
/* border: 2px solid rgb(0, 255, 106); */
display: flex;
flex-direction: column;
width: 70%;
height: 170px;
align-items: center;
gap: 10px;
}
.h2_responsive{
font-size: 14px;
}

.p_responsive{

font-size: 11px;

}
.centerslide_responsive{
display: flex;
align-items: center;
/* flex-direction: row; */
height: 100%;
width: 90%;
/* border: 1px solid blue; */
padding: 10px;
gap: 10px;
/* background-color:#ffffff41; */
}
/* .imgbox_box2{ */
/* border: 2px solid rgb(0, 255, 106); */
    /* width: 30%;
display: flex;
align-items: center;
justify-content: center;
} */
.img_responsive{
height: 55px;
width: 80px;
object-fit: initial;
}


}
