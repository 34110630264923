/* .box_card{
    width: 360px;
    height: 334px;
    display: flex;
    flex-direction: column;
   gap: 25px;
    border-radius: 6px;
    padding: 40px 20px 90px 20px;
}
.heading_card{
    display: flex;
    align-items: center;
    width: 95%;
    gap: 20px;
}
.img_card{
    width: 50px;
    height: 50px;
}
.title_card{
    font-size: 20px;
    font-weight: 700;
    text-align: left;
}
.content_card{
    font-size: 14px;
    text-align: left;
} */





.box_card{
    width: 360px;
    height: 334px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    gap: 25px;
    border-radius: 6px;
    padding: 40px 20px 90px 20px;

    transition: transform 0.4s ease-in-out, scale 0.4s ease-in-out;
    scale: 1;
    cursor: pointer;

}
.box_card:hover {
    scale: 1.03;
    /* transform: translateX(2px); */
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.2);

}
.heading_card{
    display: flex;
    align-items: center;
    width: 95%;
    gap: 20px;
}
.img_card{
    width: 50px;
    height: 50px;
}
.title_card{
    font-size: 20px;
    font-weight: 700;
    text-align: left;
}
.content_card{
    font-size: 14px;
    text-align: left;
}
@media screen and (max-width:420px) {
    .box_card{
        width: 300px;
        height: 294px;
        padding: 25px 10px 50px 10px;
    }

    .content_card{
        font-size: 13px;
        
    }
}