







 .optimizing__container {
   
    display: flex;
    gap: 0px;
    /* border: 1px solid green; */
    /* align-items: center; */
    justify-content: space-evenly;
    /* padding: 50px 96px; */
    /* padding: 30px 50px; */
   
}
.optimizing__left {
width: 37%;
margin-top: -4px;
}
.optimizing__heading {
    text-align: left;
    font-size: 28px;
    font-weight: 700;
    line-height: 1.7;

}
.optimizing__lines {
    width: 60%;
    border: 1.5px solid #F58635;
    margin-top: 10px;
    border-radius: 5px;
}
.optimizing__right {
    width: 44%;
    text-align: left;
    font-weight: 400;

}
.optimizing__text1 {
    width: 108%;
}
.optimizing__text2 {
    width: 108%;
    margin-top: 30px;
}



@media screen  and (max-width: 803px){
    .optimizing__left {
        width: 50%;

    }
    .optimizing__heading {
          font-size: 23px;
    }
    .optimizing__right {
        width: 38%;
    }

    .optimizing__lines {
       width: 70%;

    }
}


@media screen  and (max-width: 750px){
    .optimizing__container {
       flex-direction: column;
       gap: 20px;
       padding: 10px 50px;

       display: flex;
       justify-content: center;
    }
    .optimizing__left {
        width: auto;

    }
    .optimizing__heading {
          font-size: 20px;
          text-align: center;
    }
    .optimizing__right {
        width: auto;

    }
    .optimizing__lines {
         /* width: 70%; */
         display: inline-block;

         width: 60%;
         margin-left: 22%;
         
    }
}
