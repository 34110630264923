@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,100..900;1,100..900&display=swap');
.aboutuscomp_aboutus
{
    /* border: 1px solid gray; */
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 70px;
   
}
.container
{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  /* background-color: green; */
    gap: 30px;
    margin-top: 100px ;
    margin-bottom: 50px ;
    }




.container_home {
    box-sizing: border-box;
    width: 98%;
    /* border: 1px solid orange; */
    height: 100%;
    display: flex;
    align-items: center;
    gap: 0px;
    /* gap: 30px; */
    justify-content: space-around;
    margin: auto;
         
   
}

.content1_home {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
   
    width: 500px;
    height: 400px;
    
    padding-left: 20px;
   
}

.h1_home {
    font-size: 2.7rem;
    font-family: "Noto Serif", serif;
}

.btn_home {
    background-color: #FF7D29;
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
  color: white;
 border:  #1F3255;
 width: 150px;
 height: 40px;
 box-shadow:0 4px 4px 0 #0000003d ;
 cursor: pointer;
}

.content2_home {
    width: 750px;
  /* background-color: yellow; */
    height: 600px;
    padding-right: 0;
    margin-right: 0 ;
   
}

.reponsivehome_image {
    display: none;
}

.content2box_home {
    left:10px;
    position: relative;
    width: 750px;
 
    height: 500px;
    margin-top: 50px;
  
}

.women_home {
    position: absolute;
    top: 65px;
    right: 200px;
}

.circle_home {
    position: absolute;
    top: 100px;
    right: 170px;
    z-index: -1;
}

.gearb_home {
    position: absolute;
    right: 80px;
    bottom: 30px;
}

.gears_home {
    position: absolute;
    bottom: 100px;
    left: 130px;
}

.line_home {
    position: absolute;
    bottom: -50px;
    left: 305px;
}

.bar_home {
    position: absolute;
    top: -100px;
    left: 63px;
    bottom: 30px;
}

.curve_home {
    position: absolute;
    top: -10px;
    right:35px;
}

@media screen and (max-width: 1096px) {
    .container_home {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center; 
        height: 620px;
        width: 100%;
    }

    .content1_home {
        width: 540px;
        height: 350px;
    }

    .h1_home {
        font-size: 2.5rem;
    }

    .btn_home {
        width: 125px;
        height: 45px;
      
    }

    .content2_home {
        width: 540px;
        height: 350px;
        display: flex;
        align-items: center;
        justify-content: center; 
    }

    .content2box_home {
        display: none;
    }

    .reponsivehome_image {
        height: 100%;
        display: block;
        width: 90%;
        object-fit: contain;
    }
}

@media screen and (max-width: 600px) {
    .container_home {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center; 
        height: 100%;
       
        gap: 0px;
      
    }

    .content1_home {
        width: 350px;
        height: 300px;
        
    }

    .h1_home {
        font-size: 2rem;
    }

    .btn_home {
        width: 100px;
        height: 30px;
        font-size: 14px;

    }

    .content2_home {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        /* border: #1F3255 1px solid; */

         /* Ensure centering in smaller screens */
    }

    .content2box_home {
        display: none;
    }

    .reponsivehome_image {
        height: 100%;
        display: block;
        width: 90%;
        object-fit: inherit;
    }
}
