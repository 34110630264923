.container_benefits{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin:auto;
    gap: 50px;
    /* background-color: aqua; */
}
.heading_benefits{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.h1_benefits{
    font-size: 2.1rem;
   
}
.h1underline_benefits{
    margin: auto;
width: 70%;
height: 3px;
background-color:#F58634;
border-radius: 90px;

}
.result_benefits{
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 100%;
    /* border: firebrick solid 2px; */
    /* justify-content: space-between; */
    gap: 40px;
/* margin: 30px 30px; */
margin: auto;

    
}
.content_benefits{
    display: flex;
    width: 100%;
    align-items: center;
    /* justify-content: space-between; */
    /* gap: 20px; */
    /* border: #F58634 solid 2px; */
    margin: auto;
    padding-left: 50px;
}
.contentheading_benefits{
    font-size: 16px;
    font-weight: 600;
}
.contentpara_benefits{
    font-size: 16px;
}
.line_benefits{
    display: flex;
    gap: 30px;
}
.box_benefits{
    position: relative;
  /* border: rebeccapurple solid 2px; */
    right: 30px;
  display: flex;
width: 100%;
  flex-direction: column;
  justify-content: space-evenly; 
  height: 200px; 
}   
@media screen and  (max-width:1016px) 
{

    /* .container_benefits{
       
        height: 1250px;} */

    .content_benefits{
        flex-direction: column;
        align-items: center ;
        width: 100%;
        gap: 40px;
        flex-wrap: wrap;
        /* background-color: blue; */
    } 
   
    .result_benefits{
      gap: 20px;
  /* margin: 0;
     padding-left: 80px;    */
    }

}