.mainbox_serial {
    display: flex;
    align-items: center;
}

.box1_serial {
    height: 50px;
    width: 50px;
    border-radius: 5px;
    background-color: #f584345b;
    transform: rotate(45deg) scale(1);
    animation: enlarge 1s linear;
   
}

@keyframes enlarge {

    0% {
        transform: rotate(45deg) scale(0.2);
    }
    50% {
        transform: rotate(45deg) scale(0.5);
    }
    100% {
        transform: rotate(45deg) scale(1);
    }
}

.text_serial {
    text-align: center;
    padding: 13px;
    transform: rotate(-45deg);
}

.line_serial {
    height: 3px;
    width: 175px;
    background-color: #f584345b;
    margin-left: 7px;
    
    animation: scale 2s linear; 
    transform-origin: left;
    /* animation-timeline: scroll(); */
   
}

@keyframes scale {
    0% {
        transform: scaleX(30%);
    }
    50% {
        transform: scaleX(50%);
    }
    100% {
        transform: scaleX(100%);
    }
}

.sbox {
    height: 15px;
    width: 15px;
    background-color: white;
    border: 3px solid hsla(25, 91%, 58%, 0.357);
    transform: rotate(45deg);
}
