.overview__main_box {
    width: 100%;
    display: flex;
    gap: 5%;
    padding: 50px;
    text-align: justify;
    /* border: #F58634 solid 1px; */
    
    }
    .overview__left_box {
    width: 70%;
    }
    .overview__heading {
    color: #F58634;
    font-weight: 700;
    font-size: 20px;
    }
    .overview__textone {
    margin-top: 40px;
    font-size: 15px;
    font-weight: 400;
    }
    .overview__texttwo {
    margin-top: 40px;
    font-size: 15px;
    font-weight: 400;
    }
    
    .overview__img {
        height: 100%;
        width: 100%;
    
    }
    
    
    
    @media screen and (max-width:900px) {
        .overview__textone, .overview__texttwo {
            margin-top: 15px;
            font-size: 13px;
        }   
        .overview__img {
            height: 100%;
            width: 100%;
            
        }
    
    }
    
    
    @media screen and (max-width:700px) {
    
        .overview__main_box {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            text-align: center;
        }
        .overview__textone, .overview__texttwo {
            margin-top: 15px;
            font-size: 13px;
        }   
        .overview__img {
            height: 100%;
            width: 100%;
            
        }
        .overview__left_box {
          width: 100%;
          text-align: justify;
          margin-top: 20px;
        }
    
        .overview__right_box {
            width: 100%;
            height: 280px;
            }
    
    }