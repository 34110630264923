.container_colfirst{
    display: flex;
    flex-direction: column;
    width: 25%;
    /* background-color: aqua; */
    gap: 10px;
    font-size: 16px;
   /* border: 1px solid red; */
}
.img_colfirst{
    width: 150px;
}
.logo_colfirst{
    margin-top: 15px;
    display: flex;
   gap: 20px;
}
/* .container_colthird{
    width:20%;
} */
.container_colsecond , .container_colfourth , .container_colthird{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.linktext_colthird{
    text-decoration: none;
   color: black;
    
}

@media screen and (max-width:670px) {
    .container_colfirst{
        font-size: 12px;
        width: 100%;
    }
    .container_colthird{
        width:120px;
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 10px;
    }
    .footerpara_colfirst{
        width: 98%;
        font-size: 16px;
        /* margin: auto; */
        /* border: 1px solid green; */

    }
    .container_colsecond , .container_colfourth{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}
