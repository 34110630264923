.driving__main_div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    margin-top: 10px ;
    /* border: #000000 solid 1px; */
    width: 100%;
}
.driving__heading{
    text-align: center;
    color: #1F3255;
    font-weight: 700;
    font-size: 33px;
    line-height: 38px;
}
.driving__text_box {
    color: #000000;
    font-weight: 400;
    margin-top: -15px;
    /* margin-top: -25px; */
    text-align: center;
}

.driving__btn {
    padding: 5px 15px;
    border-radius: 5px;
    border: 2px solid #25679C;
    background-color: white;
    font-weight: 700;
    color: #25679C;
    cursor: pointer;
}

@media screen and (max-width:645px) {
    .driving__main_div {
        padding: 0 15px;
    }
    .driving__heading {
        font-size: 30px;
    }

    .driving__texttwo .driving__textone  {
        font-size: 20px;
        text-align: center;
        /* margin-top: 10px; */
    }
}
@media screen and (max-width:575px )  and (min-width:538px){
    .driving__main_div {
        padding: 0 15px;
    }
    .driving__heading {
        font-size: 28px;
        /* border: 1px solid green ; */
    }

    .driving__texttwo .driving__textone  {
        font-size: 20px;
        text-align: center;
        
    }

}
