.mySwiper .swiper-pagination-bullet-active{
    background: #F58634;
}
.container_career{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-around; */
    margin:auto;
    
    gap: 80px;
    /* border: #F58634 1px solid; */
}
.heading_career{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.h1_career{
    font-size: 2.1rem;
}
.h1underline_career{
    margin: auto;
    width: 60%;
    height: 3px;
    background-color:#F58634;
    border-radius: 90px;

}
.content_career{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    /* margin-left: 30px; */
   width: 1100px;
   /* border: black 1px solid; */
   gap: 10px;
    
}
.laptop_career{
    width: 548px;
    object-fit: initial;
    height: 320px;
   
    }
.team_career{
height: 162px;
width: 100%;
object-fit: initial;
}

.screen_career{
height: 355px;
width: 260px;
margin: 0;
box-shadow: none;
}
.container1_career{
    width: 550px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    
}


.reponsiveslider_career{
   display: none;

}
.pagination_swiper
{
    
    
  height: 50px;
}

@media screen and (max-width:1120px) {
    
    .slide_card{
       display: flex;
       align-items: center;
       justify-content: center;
    }
.laptop_career{
    width: 100vw;
    height:60%;
     position: relative;
     overflow: hidden;
     object-fit: inherit;
    }

.reponsiveslider_career{
    max-width: 1120px;
    /* width: 100%; */
    height: 700px;
    display: flex;
    /* flex-direction: column; */
    position: relative;
    /* border: #F58634 2px solid; */
    
    
}
.slidercontainer_career{
    /* border: #8024aa 2px solid; */
width: 100%;
height: 50%;
/* padding-left: 20px; */
position: absolute;
z-index: 2;
bottom: 15%;
right: 0;
 
}

.container_career{
display: none;
}




}

@media screen and (max-width:600px) {
    

.laptop_career{
  width: 100vw;
    height:60%;
     position: relative;
     overflow: hidden;
     object-fit: inherit
   
  
}
.reponsiveslider_career{
    width: 100vw;
    height: 600px;
    display: flex;
    /* flex-direction: column; */
    position: relative;
    /* border: #F58634 2px solid; */


}
.slidercontainer_career{
width: 100%;
height: 55%;
position: absolute;
z-index: 2;
bottom: 10%;
right: 0;

}
.container_career{
display: none;
}

}