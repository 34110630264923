



.container {
    margin: 8% auto;
    padding: 20px 40px;
}
.heading {
  padding: 40px 0px;
}
 .h3 {
    margin-bottom: 25px;
    color: #1F3255;
}
.lastUpdate {
    display: inline;
    border: 1.5px solid #F7A467;
    padding: 10px 25px;
}
.box1, .box2, .box3, .box4, .box5, .box6, .box7, .box8, .box9, .box10, .box11 {
    margin: 25px 0px;
}
.unorderlist {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.list {
    margin-left: 20px;
}
.paragraph {
    margin-top: 10px;
}

@media screen and (max-width: 340px) {
    .lastUpdate {
        display: inline;
        border: 1.5px solid #F7A467;
        padding: 0px;
    }
}