.totalprojects__main_div {
    height: 115px;
    width: 130px;
    display: flex;
    align-items: center;
    margin: 100px auto;
    flex-direction: column;
    box-shadow: inset 3px 2px 3px 3px #ccc;
    border-radius: 8px;
    gap: 10px;
  
  }
  .totalprojects__graphimg {
    width: 75px;
    height: 38px;
    margin-right: 3px;
  }
  .totalprojects__heading{
      font-size: 12px;
      font-weight: 300;
      margin-top: 10px;
  }
  .totalprojects__number {
     margin-top: -13px;
     font-size: 12px;
  }
  .totalprojects__text{
    font-size: 13px;
    margin-top: 5px;
  }
  
  