.container_aboutuspage{
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: 100%;
    width: 100%;
    margin: 130px auto; 
    margin-bottom: 100px; 
    /* border: 1px solid red; */
    align-items: center;
}