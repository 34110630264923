.process__main_box {
    padding: 30px;
    width: auto;
}
.process__top {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.process__heading {
    font-size: 28px;
    margin-bottom: 10px;
}
.process__lines {
    width: 57px;
    border: 1.5px solid #F58634;
    border-radius: 10px;
    margin-bottom: 10px;
}
.process__bottombox {
    display: flex;
    justify-content: center;
    gap: 25px;
    margin-top: 20px;
}

.process__data{
    margin-top: -10px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 50px;
}
.process__dataitem {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.process__text {
    font-weight: 400;
} 

.process__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    gap: 85px;
} 


.step {
    width: 22px;
    height: 22px;
    background-color: #ff9800;
    color: black;
    font-weight: 800;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    opacity: 0;
    animation: appear 1s forwards;
    z-index: 1;
    box-shadow: 0 0 1px 7px rgba(245, 134, 52, 0.3);
}
@keyframes appear {
    to {
        opacity: 1;
    }
}


.step:nth-child(1) {
    animation-delay: 0s;
}

.step:nth-child(2) {
    animation-delay: 1s;
}

.step:nth-child(3) {
    animation-delay: 2s;
}

.step:nth-child(4) {
    animation-delay: 3s;
    margin-bottom: 15px;
}
.vertical_lineone {

    width: 105px;
    height: 27px;
    position: absolute;
    top: 65px;
    animation: growOnScroll 20s forwards;
}
.vertical_lineone::before {
    content: "---------------";
    color: black;
    
}

.vertical_linetwo {
    width: 82px;
    height: 27px;
    position: absolute;
    top: 162px;
    animation: growOnScroll 20s forwards linear;
}
.vertical_linetwo::before {
    content: "---------------";
    color: black;
}

.vertical_linethree {
    width: 105px;
    height: 28px;
    position: absolute;
    top: 280px;
    animation: growOnScroll 20s forwards linear;
}
.vertical_linethree::before {
    content: "---------------";
    color: black;
}

@keyframes growOnScroll {
    0% {
        transform: rotate(90deg) scaleY(0);
    }
    100% {
        transform: rotate(90deg) scaleY(1);
    }
}



@media screen and (max-width: 896px) {
    .process__container {
        gap:99px;
    }

    .vertical_lineone::before {
        content: "-----------------";
        color: black;
    }
    .vertical_linetwo {
        width: 90px;
        height: 27px;
        position: absolute;
        top: 182px;
        animation: growOnScroll 20s forwards linear;
    }
    .vertical_linetwo::before {
        content: "-----------------";
        color: black;
    }
    .vertical_linethree {
        width: 103px;
        height: 27px;
        position: absolute;
        top: 308px;
        animation: growOnScroll 20s forwards linear;
    }
    .vertical_linethree::before {
        content: "------------------";
        color: black;
    }
}
@media screen and (max-width: 722px) {
    .process__data {
        gap: 40px;
    }
}
@media screen and (max-width: 501px) {
    .process__data {
        gap: 30px;
    }
}
@media screen and (max-width: 418px) {
    .process__container {
        gap:98px;
        /* gap:101px; */
    }
    .vertical_lineone::before {
        content: "-------------------";
        color: black;
    }
    .vertical_linetwo {
        width: 110px;
        height: 27px;
        position: absolute;
        top: 192px;
        animation: growOnScroll 20s forwards linear;
    }
    .vertical_linetwo::before {
        content: "-----------------";
        color: black;
    }
    .vertical_linethree {
        width: 103px;
        height: 27px;
        position: absolute;
        top: 309px;
        animation: growOnScroll 20s forwards linear;
    }
    .vertical_linethree::before {
        content: "------------------";
        color: black;
    }
}
@media screen and (max-width: 408px) {
    .extraClass {
        margin-top: 10px;
    }
    .step:nth-child(3) {
        margin-top: -10px;
    }
    .step:nth-child(5) {
        margin-top: 10px;
    }
    .step:nth-child(7) {
        margin-top: 25px;
    }

    .vertical_linethree::before {
        content: "---------------------";
        color: black;
    }
    .vertical_linethree {
        width: 115px;
        height: 27px;
        position: absolute;
        top: 315px;
    }
    .vertical_linetwo {
        width: 130px;
        height: 27px;
        position: absolute;
        top: 192px;
        animation: growOnScroll 20s forwards linear;
    }
    .vertical_linetwo::before {
        content: "-------------------";
        color: black;
    }
}
@media screen and (max-width: 403px) {
    .vertical_linethree::before {
        content: "-----------------------";
        color: black;
    }
    .vertical_linethree {
        width: 120px;
        height: 26px;
        position: absolute;
        top: 325px;
    }
    .process__container{
        margin-top: 8px;
        gap: 103px;
    }
    .vertical_linetwo {
        width: 129px;
        height: 26px;
        position: absolute;
        top: 199px;
        animation: growOnScroll 20s forwards linear;
    }
    .vertical_linetwo::before {
        content: "-------------------";
        color: black;
    }
}
@media screen and (max-width: 357px) {

    .step:nth-child(7) {
        margin-top: 40px;
    }
    .vertical_linethree::before {
        content: "-------------------------";
        color: black;
    }
    .vertical_linethree {
        width: 140px;
        height: 26px;
        position: absolute;
        top: 340px;
    }
}

@media screen and (max-width: 340px) {


    .vertical_linethree::before {
        content: "---------------------------";
        color: black;
    }
    .vertical_linethree {
        width: 150px;
        height: 26px;
        position: absolute;
        top: 358px;
    }
    .process__container{
        margin-top: 7px;
        gap: 111px;
    }
    .vertical_linetwo {
        width: 129px;
        height: 26px;
        position: absolute;
        top: 199px;
        animation: growOnScroll 20s forwards linear;
    }
    .vertical_linetwo::before {
        content: "-----------------------";
        color: black;
    }
}
@media screen and (max-width: 308px) {


    .vertical_linethree::before {
        content: "---------------------------";
        color: black;
    }
    .vertical_linethree {
        width: 150px;
        height: 26px;
        position: absolute;
        top: 378px;
    }
    .process__container{
        margin-top: 7px;
        gap: 118px;
    }
    .vertical_linetwo {
        width: 129px;
        height: 26px;
        position: absolute;
        top: 199px;
        animation: growOnScroll 20s forwards linear;
    }
    .vertical_linetwo::before {
        content: "-----------------------";
        color: black;
    }
}
