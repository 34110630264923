.main_container {
    height: 500px;
    width: 1300px;
    display: flex;
    align-items: center;
    gap: 20px;
    flex-direction: column;
    margin: auto;
}

.heading {
    margin-top: 30px;
}
.review {
    display: flex;
    /* flex-wrap: wrap; */
    align-items: center;
    gap: 60px;
    justify-content: center;
    width: 100%;
    height: 450px;
}
@media screen and (max-width:1288px) {
    .main_container {
       height: 100%; 
       width: 100%;
    }  
    .review {
        height: 100%;
    }
}
@media screen and (max-width:660px) {
    .review {
        flex-wrap:wrap;
    }
}
@media screen and (max-width:330px) {
    .heading {
        font-size: 12px;
    }
}