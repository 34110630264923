.privacy_ol{
    list-style-type: lower-alpha;
}

.container {
    margin: 8% auto;
    padding: 20px 40px;
}
.heading {
    text-align: center;
    padding: 40px 0;
}

.h4, .h5, .h6 {
    color: #1F3255;
}
.h4 {
    font-size: 20px;
}
.h5, .h6 {
    font-size: 18px;
    margin-bottom: 5px;
}
.box1, .box3, .box8 {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.hr {
    margin-top: 40px;
}
.box2, .box4, .box5, .box6, .box7 {
    margin: 25px 0;
}
.box4 .paras, .box5 .paras, .box6 .paras, .box7 .paras {
    margin-top: 15px;
}
.Privacy_li {
    margin-left: 30px;
}
.Privacy_olli {
    margin-left: 20px;
}
