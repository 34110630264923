.container_benefits{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
    justify-content: center;
    /* margin: 50px auto; */
    /* gap: 50px; */
}
.heading_benefits{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.h1_benefits{
    font-size: 2.1rem;
   text-align: center;
}
.h1underline_benefits{
    margin: auto;
width: 70%;
height: 3px;
background-color:#F58634;
border-radius: 90px;

}
.cards_benefit{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 1080px;
    /* border: 2px solid black; */
    flex-wrap: wrap;
    gap: 60px;
    margin: auto;
}
.card_benefit{
    display: flex;
    height: 100%;
    width: 500px;
    gap: 20px;

    /* align-items: flex-start; */
}
.cardtext_benefit{
    display: flex;
    flex-direction: column;
    gap: 15px;

}
/* @media screen and (max-width:1322px) {
    .container_benefits{
        height: 875px;
    }
} */
@media screen and (max-width:1016px) {
    /* .container_benefits{
        /* height: 875px; */
        /* width: 100%;
    }  */
    .cards_benefit{
width: 75%;
gap: 30px;
/* justify-content: space-around; */
margin: 0;
    }

}
@media screen and (max-width:442px) {
    /* .container_benefits{
        height: 870px;
        
    } */

    .cards_benefit{
        gap: 30px;
        width: 90%;
        margin: 0;

    }


    .h1_benefits{
        font-size: 1.75rem;
       
    }
    .h1underline_benefits{
     
    width: 50%;
   
    
    }
    .cardtitle_benefit{
        font-size: 20px;
    }
    .cardpara_benefit{
        font-size: 15px;
    }
.pic_benefit{
    width: 40px;
    height: 40px;
    object-fit: contain;
}

}