.container_footer{
    display: flex;
    width: 100%;
    height: 360px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    /* border: 1px solid brown; */
    /* background-color: #F7F7FA; */
    padding-bottom: 30px;
}
.box_footer{
    display: flex;
    justify-content: space-around;
    width:100%;
    margin: 0 auto;
}
.copyright_footer{
    /* padding-left: 80px; */
    /* background-color: burlywood; */
    width: 100%;
    text-align: center;
 
}
.linktext_footer{
    color: #FF7D29;
    font-weight: 600;
    text-decoration: none;
}
@media screen and (max-width:670px) {
    .container_footer{
        height: 100%;
        font-size: 16px;
        gap: 20px;
        align-items: start;
        /* gap: 20px; */
        /* margin-bottom: 10px; */
        padding-bottom: 15px;
    }
    .box_footer{
        flex-direction: column;
        align-items: start;
        justify-content: space-between;
        padding-left: 20px;
        gap: 20px;
        /* background-color: #F7F7FA; */

    }
   
}
@media screen and (max-width:450px) {
    .copyright_footer{
        font-size: 14px;
        /* padding-left: 0px; */
    }


}