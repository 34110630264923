.faqchild1_faq, .faqchild2_faq {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* border-bottom: 1px solid #ccc; */
    margin-bottom: 10px;
    border-radius: 5px;
  }
  
  .faqchild1_faq {
    background-color: #fff;
  }
  
  .faqchild2_faq {
    padding: 25px 5px 25px 5px;
    background-color: #f1f1f1;
  }
  
  .ques_faq {
    display: flex;
    justify-content: space-between;
    padding-right:10px ;
    cursor: pointer;
  }
  
  .border1_faq {
    border-bottom: none;
   
   
  }
  .border2_faq {
    width: 102%;
    padding-top: 20px;
    border-bottom: 1px solid #c2c2c2;
    
  }
  .ans_faq {
    padding-left: 16px;
    color: #575757;
    width: 90%;
    text-align: justify;

  }
  .p_faqchild{
    padding-left: 20px;
    color: #000000;
    font-size: 1rem;
    font-weight: 500;
  }
  @media screen and (max-width:490px) {
    
    .ans_faq {
      padding-left: 16px;
      color: #575757;
      width: 90%;
      text-align: left;
      font-size: 12px;

    }
    .p_faqchild{
      width: 80%;
      padding-left: 20px;
      color: #000000;
      font-size: 14px;
      font-weight: 500;
    }
    

  }