.filenames_project {
    margin-top: 10px;
  }
.job_errmsg {
    color: red;
    font-size: 14px;
}
  
  .filename_project {
    right: 120px;
    font-size: 14px;
    color: green;
    /* position: absolute;
    bottom: 60px; */
  }
  .fileupload_project {
    background-color: #F6F6F6;
    width: 640px;
    height: 230px;
    border: 1px dashed #000000;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    transition: background-color 0.3s ease;
  }
  
  .fileupload_project.dragging {
    background-color: #e0e0e0;
  }




.container_job{
    width: 97vw;
    height: 1100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin: auto;
    /* position: absolute;
    top: 100px; */
}
.headingdiv_job{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.heading_job{
    color: #1F3255;
    font-size:35px;
    font-weight: 700;
}
.headingP_job{
    font-size:18px;
    font-weight: 500;
}
.form_job{
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;

}
.formbox1_job{
    display: flex;
    gap: 40px;
}
.formelem_job{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.formcol1_job{
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.formlabel_job{
font-size: 16px;

}
.forminput_job{
border-radius: 4px;
outline: none;
border: 1px solid #F6F6F6;
background-color: #F6F6F6;
width: 300px;
height: 50px;
padding: 30px;
}

.textelem_job{
    display: flex;
    flex-direction: column;
    gap: 10px;
 

}
.textinput_job{
    border-radius: 4px;
    outline: none;
    border: 1px solid #F6F6F6;
    background-color: #F6F6F6;
    width: 640px;
    height: 100px;
    padding: 10px; 
    text-wrap: wrap; 
    resize: none; 

}
.fileupload_project{
    background-color: #F6F6F6;
    width: 640px;
    height: 230px;
    border: 1px dashed #000000;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.browse_project{
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 90px;
}
.browsetext_project{
position: absolute;
text-align: center;
color:#6A6A6A;
padding-top: 7px ;
width: 160px;
height: 40px;
/* padding-left: 5px; */
left: 0;
top:-17px;
border:1px solid #aaa6a6;
border-radius: 4px;
/* box-shadow: .1px .1px .1px 0 #888888; */
background-color: #F6F6F6;
}
.browseinput_project{
visibility: hidden;
}
    .drag_project{
        width: 300px;
        height: 120px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column  ;
    }
    .img_project{
        object-fit: contain;
        width: 30px;
        height: 30px;
    }

.btn_job{
    cursor: pointer;
    background-color: #FF7D29;
    border-radius: 10px;
    font-weight: bold;
    color: white;
   border:  #1F3255;
   width: 150px;
   height: 40px;
   box-shadow:0 4px 4px 0 #0000003d ;
}
@media screen and (max-width:654px) {
    .formbox1_job{
        flex-direction: column;
    }
    .container_job{
        height: 1200px;
    }
    .browse_project{
       top: 8px;
    }
    
    .form_job{
        align-items: center;
        width: 80%;
        /* background-color: aqua; */
    }
    .formbox1_job{
        flex-direction: column;
       /* background-color: #FF7D29; */
       width: 80%;
       align-items: center;
    }
    /* .formelem_general{
        width: 100%;
        background-color: blueviolet;
    } */
    .forminput_job{
       /* background-color: black; */
       width: 400px;
       padding: 15px;
    }
    
   
    /* .textelem_general{
        align-items: center;
    } */
    .textinput_job{
        width: 400px;
    }
    /* .formlabel_general{
       
    } */
    .fileupload_project{
        width: 400px;
        height: 100px;
    }


    .heading_job{
        color: #1F3255;
        font-size:35px;
        font-weight: 700;
        text-align: center;
    }

    .drag_project {
        display: none;
    }
    .filename_project {
        right: 150px;
        bottom: 100px;
    }
}


@media screen and (max-width:420px) {
    .formbox1_job{
        flex-direction: column;
    }
    .container_job{
        height: 1200px;
    }
    
    .form_job{
        align-items: center;
        width: 80%;
        /* background-color: aqua; */
    }
    .formbox1_job{
        flex-direction: column;
       /* background-color: #FF7D29; */
       width: 80%;
       align-items: center;
    }
    /* .formelem_general{
        width: 100%;
        background-color: blueviolet;
    } */
    .forminput_job{
       /* background-color: black; */
       width: 305px;
       padding: 15px;
    }
    
   
    /* .textelem_general{
        align-items: center;
    } */
    .textinput_job{
        width: 305px;
        padding: 10px;
        /* text-wrap: wrap; */
        /* text-align: left; */
    }
    /* .formlabel_general{
       
    } */
    .fileupload_project{
        width: 305px;
    }


    .heading_job{
        
        font-size:25px;
       
    }
    .headingP_job{
        font-size:16px;
        
    }

    .formlabel_job{
        font-size: 14px;
    }
    .filename_project {
        right: 100px;
        bottom: 95px;
    }
}