.container_staff{
    width: 100%;
    height:820px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    /* border: 1px solid greenyellow; */
    /* margin: 130px auto; */
}
.heading_staff{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.h1_staff{
    font-size: 2.1rem;
    text-align: center;
}
.h1underline_staff{
    margin: auto;
    width: 40%;
    height: 3px;
    background-color:#F58634;
    border-radius: 90px;
}
.box_card{
    /* border: 1px solid black; */
    /* width: 90%; */
    width:1150px ;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    gap: 30px;
    justify-content: center;
}
@media screen and (max-width:1149px) {
    .container_staff{
        height: 100%;
   
    }
    .box_card{
       width: 100%;
        display: flex;
        flex-wrap: wrap;
        text-align: center;
       justify-content: center;
    } 

}

@media screen and (max-width:420px) {
    .h1_staff{
        font-size: 1.6rem;
   
    }

}